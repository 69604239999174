/* eslint-disable sonarjs/no-identical-functions */
import { Link } from 'gatsby';
import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import config from '../../config.json';
import { Logo } from '../svg/logo';
import { useGraphQL } from '../hooks';

function Footer() {
  const firstCol = config.siteNavigation.slice(
    0,
    config.siteNavigation.length / 2
  );
  const secondCol = config.siteNavigation.slice(
    config.siteNavigation.length / 2
  );
  const { gamblingBanner } = useGraphQL();
  return (
    <footer aria-labelledby="footerHeading" className="relative bg-blue-dark">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-screen-2xl sm:px-6 lg:py-16 lg:px-8">
        <div className="grid items-start lg:grid-cols-2">
          <div className="grid gap-8 md:grid-cols-2">
            <div className="grid col-span-1">
              <Link
                to="/"
                className="max-w-xs text-white transition duration-150 ease-in-out hover:text-gray-300"
              >
                <span className="sr-only">{config.title}</span>
                <Logo className="fill-current" />
              </Link>
            </div>
            <nav className="grid items-start grid-cols-2 gap-8 mt-4 lg:mt-0 lg:pr-4">
              <div className="space-y-4">
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase">
                  Links
                </h3>
                {firstCol.map(({ label, slug }) => (
                  <div key={slug} className="text-sm">
                    <Link
                      to={slug}
                      className="tracking-wider text-gray-300 uppercase transition duration-150 ease-in-out hover:text-white hover:underline"
                    >
                      {label}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="space-y-4">
                {secondCol.map(({ label, slug }) => (
                  <div key={slug} className="text-sm">
                    <Link
                      to={slug}
                      className="tracking-wider text-gray-300 uppercase transition duration-150 ease-in-out hover:text-white hover:underline"
                    >
                      {label}
                    </Link>
                  </div>
                ))}
              </div>
            </nav>
          </div>
          <div className="grid mt-16 lg:mt-0 md:grid-cols-2 lg:border-l lg:border-white lg:border-opacity-25 lg:pl-8">
            <div>
              <h3 className="text-sm font-semibold tracking-wider text-white uppercase">
                Contact Details
              </h3>
              <dl className="mt-4 space-y-4 text-gray-300">
                <div>
                  <dt className="inline font-semibold tracking-wider text-white">
                    Phone:{' '}
                  </dt>
                  <dd className="inline">
                    <a
                      href={`tel:${config.phone}`}
                      className="transition duration-150 ease-in-out hover:text-white hover:underline"
                    >
                      {config.phone}
                    </a>
                  </dd>
                </div>
                <div>
                  <dt className="inline font-semibold tracking-wider text-white">
                    Email:{' '}
                  </dt>
                  <dd className="inline">
                    <a
                      href={`mailto:${config.email}`}
                      className="transition duration-150 ease-in-out hover:text-white hover:underline"
                    >
                      {config.email}
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
            <div className="space-y-4">
              <dl className="space-y-4 text-gray-300">
                <div>
                  <dt className="inline font-semibold tracking-wider text-white">
                    Location:{' '}
                  </dt>
                  <dd className="inline">
                    {config.address.line1},{' '}
                    <span className="inline-block">{config.address.line2}</span>
                  </dd>
                </div>
                <div>
                  <dt className="inline font-semibold tracking-wider text-white">
                    Opening Hours:{' '}
                  </dt>
                  <dd className="inline">{config.openingHours}</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div className="items-center pt-8 mt-16 border-t border-white border-opacity-25 md:flex md:items-center md:justify-between">
          <div className="flex items-center space-x-6 md:order-2">
            <h3 className="text-sm font-semibold tracking-wider text-white uppercase">
              Follow Us
            </h3>
            <a
              href={config.facebook}
              className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-300 focus:text-white"
            >
              <span className="sr-only">Facebook</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
            <a
              href={config.instagram}
              className="text-gray-400 transition duration-150 ease-in-out hover:text-gray-300 focus:text-white"
            >
              <span className="sr-only">Instagram</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
          <div className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            <p>
              Help is close at hand. GambleAware{' '}
              <a href="https://gambleaware.nsw.gov.au">
                gambleaware.nsw.gov.au
              </a>{' '}
              <a href="tel:1800858858">1800 858 858</a>
            </p>
            <br />
            <a
              href="https://pd.digital"
              className="transition duration-150 ease-in-out hover:text-white hover:underline group"
            >
              Designed and developed by{' '}
              <span className="font-semibold text-white group-hover:text-white">
                PD
              </span>
            </a>
          </div>
        </div>
        {/* <GatsbyImage
          image={gamblingBanner.childImageSharp.gatsbyImageData}
          className="flex-1 mt-6"
          alt="Gambling help hotline"
          objectFit="contain"
        /> */}
      </div>
    </footer>
  );
}

export { Footer };
